.btn {
  &.btn-mini {
    padding: 0px 5px 0px 5px !important;
    line-height: 1.4;

    svg {
      height: 12px;
      margin-top: -3px;
    }
  }

  &.btn-maps {
    //background: #fff;
    //border: 2px solid #fff;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    //color:rgb(25,25,25);
    //font-family: Roboto,Arial,sans-serif;
    font-size: 16px;
    line-height: 36px;
    padding: 0px 17px;
    height: 40px;
  }
}