.inputSectionsSort-sortItem {
  //background: $gray-100;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  margin-top: -1px;
  padding: 0.25rem 0.5rem ;
  user-select: none;
  cursor: move;
  background: #fff;
  display: flex;

  &:last-child {
    margin-bottom: -1px;
  }

  .sortHandle {
    padding-right: 0.5rem;
    .icon {
      margin-top: -3px;
      color: $gray-500;
      font-size: 20px;
    }
  }
  .sortCity {
    flex: 0 0 20%;
  }

  .sortName {
    flex: 1 1 auto;
  }

  .sortAuthority {
    flex: 0 0 20%;
  }

  .sortCode {
    flex: 0 0 auto;
  }

  &.draggedItem {
    background: lighten($primary, 45%);
    border: 0;
  }
}