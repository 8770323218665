.rc-select {
  position: relative;

  svg {
    margin-top: -4px;
  }

  .rc-select-selector {
    position: relative;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);

    .rc-select-selection-search {
      display: block;

      .rc-select-selection-search-input {
        display: block;
        width: 100%;
        height: $input-height;
        padding: $input-padding-y $input-padding-x;
        font-family: $input-font-family;
        @include font-size($input-font-size);
        font-weight: $input-font-weight;
        line-height: $input-line-height;
        color: $input-color;
        //background-color: $input-bg;
        background-clip: padding-box;
        outline: 0;
        border: 1px solid transparent;
        cursor: auto;

        @include border-radius($input-border-radius, 0);

        @include box-shadow($input-box-shadow);
        @include transition($input-transition);
      }
    }
          
    .rc-select-selection-item,
    .rc-select-selection-placeholder {
      position: absolute;
      top: 7px;
      left: $input-padding-x + 0.1;
      pointer-events: none;
    }

    .rc-select-selection-placeholder {
      opacity: 0.5;
    }


    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;

      .rc-select-selection-overflow-item {
        padding: 5px;
       
        .rc-select-selection-item {
          position: relative;
          background: #eee;
          top: auto;
          left: auto;
          padding: 4px 5px;
          cursor: pointer;

          &:hover {
            background: #bbb;
          }

          .rc-select-selection-item-remove {
            margin-left: 5px;
            color: $danger;
          }
        }

        &.rc-select-selection-overflow-item-suffix {
          //background: red;
          padding: 0;

          .rc-select-selection-search-input {
            width: 100%;
            padding: 0;
            margin: 0;
            outline: 0;
            height: auto;
          }

          .rc-select-selection-search-mirror {
            display: none;
          }
        }
      }
    }
  }

  .rc-select-arrow {
    position: absolute;
    height: 6px;
    width: 6px;
    content: "";
    top: 12px;
    right: 12px;

    .rc-select-arrow-icon {
      &:after {
        content: "";
        width: 0;
        height: 0;
        display: inline-block;
        border: 6px solid transparent;
        border-top-color: #999;
    }
    }
  }

  .rc-select-clear {
    position: absolute;
    height: 6px;
    width: 6px;
    content: "";
    top: 4px;
    right: 30px;
    font-size: 20px;
    color: $danger;
    cursor: pointer;
  }

  &.rc-select-focused {

    .rc-select-selector {

      .rc-select-selection-search {

        .rc-select-selection-search-input {
          @include form-control-focus($ignore-warning: true);
        }
      }

      .rc-select-selection-item {
      }
    }
  }

  &.rc-select-single {

  }

  &.rc-select-open {
  
  }

  &.rc-select-multiple {

  }

  &.size-sm {

    .rc-select-selector {
  
      .rc-select-selection-search {

        .rc-select-selection-search-input {
          height: $input-height-sm;
          padding: $input-padding-y-sm $input-padding-x-sm;
          @include font-size($input-font-size-sm);
          line-height: $input-line-height-sm;
        }
      }
    }
  }

  &.select-in-table {
    .rc-select-selector {
      position: relative;
      border: 0;
      border-radius: 0;

      .rc-select-selection-search {
      
        .rc-select-selection-search-input {
          height: 47px;
        }
      }

      .rc-select-selection-item,
      .rc-select-selection-placeholder {
        top: 12px;
      }
    }

    .rc-select-arrow {
      top: 16px;
    }
  }
}

.rc-select-dropdown {
  border: $input-border-width solid $input-border-color;
  min-height: 100px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  overflow: hidden;

  @include border-radius($input-border-radius, 0);

  svg {
    margin-top: -4px;
  }

  &.rc-select-dropdown-placement-bottomLeft {

  }

  &.rc-select-dropdown-hidden {
    display: none;
  }

  .rc-select-item {
    font-size: 16px;
    line-height: 1.5;
    padding: 4px 16px;


    // >>> Group
    &.rc-select-item-group {
      color: #999;
      font-weight: bold;
      font-size: 80%;
    }

    // >>> Option
    &.rc-select-item-option {
      position: relative;
      cursor: pointer;


      .rc-select-item-option-grouped {
        padding-left: 24px;
      }

      .rc-select-item-option-state {
        position: absolute;
        right: 0;
        top: 4px;
        pointer-events: none;
        display: none;
      }

      // ------- Active -------
      &.rc-select-item-option-active {
        background: #eee;
      }

      // ------- Selected -------
      &.rc-select-item-option-selected {
        background: #f8f8f8;
      }

      // ------ Disabled ------
      &.rc-select-item-option-disabled {
        color: #999;
        cursor: auto;
      }
    }

    // >>> Empty
    .rc-select-item-empty {
      text-align: center;
      color: #999;
    }
  }
}
