.header-search {
 
  .search-icon {
    width: 30px;
    height: 30px;
    color: rgba(#fff, 0.8);
    line-height: 27px;
    text-align: center;
    border-radius: 3px;
    margin-top: 2px;
    cursor: pointer;
    transition: 0.3s;

    .icon {
      font-size: 20px;
    }

    &:hover {
      background: rgba(#fff, 0.2);
      color: #fff;
    }
  }
}

.search-modal {

  .search-form {
    flex: 1 1 auto;

    .search-field {
      font-size: 30px;
      font-weight: 500;
      border: 0;
      outline: none;
      margin-bottom: 0.5rem;
      width: 100%;

      &::placeholder {
        color: $gray-400;
      }
    }

    .search-filters {

      .filter-item {
        margin-right: 0.5rem;
        margin-bottom: 0;
        
        &:last-child {
          margin-right: 0;
        }

        input[type="radio"] {
          display: none;
        }

        span {
          display: block;
          padding: 0 6px;
          border-radius: 3px;
          cursor: pointer;
          font-weight: 500;

          &:hover {
            color: $primary;
          }
        }

        input[type="radio"]:disabled + span {
          color: $gray-400;
        }

        input[type="radio"]:checked + span {
          background: $primary;
          color: #fff;
          cursor: default;
        }
      }
    }
  }

  .results {
    height: 600px;
    overflow-y: scroll;

    .results-item {
      border-bottom: 1px solid $gray-200;
      padding: 0.5rem 1rem;
      display: block;
      text-decoration: none;

      &:hover {
        background: $gray-100;
      }

      &:last-child {
        border-bottom: 0;
      }

      .item-title {
        font-weight: 500;
        color: $body-color;
      }
    }
  }

  .modal-footer {
    justify-content: flex-start;
  }
}
