.error-debug-block {
  background: lighten($danger, 40%);

  .card {
    border: 0;
    background: transparent;

    .card-header {
      border: 0;
      background: transparent;
      color: $danger;
      cursor: pointer;
    }

    .card-body {
      border: 0;
    }
  }
}