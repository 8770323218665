.rc-table {
  position: relative;

  .rc-table-container {

    table {
      border-spacing: 0px;
      width: 100%;
      position: relative;
      border-collapse: separate;

      thead.rc-table-thead {
        position: sticky;
        top: 0px;
        width: 100%;

        tr {

          th.rc-table-cell {
            border-bottom: 1px solid #dbdbdb;
            background: #f8f8f8;
            height: 34px;
            padding: 0 0.5rem;
            font-size: 14px;
            border-right: 1px solid #dbdbdb;

            &:last-child {
              border-right: 0;
            }

            &.cell-sortable {
              cursor: pointer;
            }

            .link-like {
              color: $link-color;
              cursor: pointer;

              &:hover {
                color: $link-hover-color;
              }

              &.active {
                color: $danger;

                &:hover {
                  color: darken($danger, 10%);
                }
              }
            }
          }
        }
      }
      tbody.rc-table-tbody {

        tr.rc-table-row {

          td.rc-table-cell {
            border-bottom: 1px solid #dbdbdb;
            padding: 0.5rem 0.75rem 0.5rem 0.75rem;

            .rc-table-row-expand-icon {
              display: inline-block;
              width: 16px;
              height: 16px;
              border: 1px solid currentColor;
              color: #aaa;
              vertical-align: middle;
              text-align: center;
              cursor: pointer;
              line-height: 13px;
              margin-right: 0.5rem;
              margin-left: 0.5rem;
              margin-top: -2px;


              &.rc-table-row-expanded::after {
                content: '-';
              }
          
              &.rc-table-row-collapsed::after {
                content: '+';
              }

              &.rc-table-row-spaced {
                visibility: hidden;
              }
            }

            .rc-table-row-indent.indent-level-1 {
              display: inline-block;
              width: 1.5rem;
            }

            .rc-table-row-indent.indent-level-2 {
              display: inline-block;
              width: 2.5rem;
            }
          }

          &:last-child {

            td.rc-table-cell {
              border-bottom: 0;
            }
          }
        }

      }
    }
  }

  &.rc-table-clickable tbody tr {
    cursor: pointer;

    &:hover {
      background: rgba(0,0,0,0.05);
    }

    &.rc-table-placeholder:hover {
      background: transparent;
      cursor: auto;
    }
  }
}


.rc-table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .wrapper-table {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .wrapper-meta {
    flex: 0 0 34px;
    background: #f8f8f8;
    border-top: 1px solid #dbdbdb;
    padding-left: 1rem;
    padding-right: 1rem;

    .meta-text {
      line-height: 35px;
    }

    .btn {
      padding: 0 0.50rem;
      margin-top: -4px;
      background: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      &:focus {
        box-shadow: none;
      }
    }

    .search-form {
      display: inline-block;
      margin: 0;
      position: relative;

      .form-control {
        height: auto !important; //.5em + 0.5rem + 2px;
        padding: 0.15rem 0.5rem !important;
        font-size: 0.875rem;
        line-height: 1.2;
        margin: 0;
        float: left;
        margin-bottom:-6px;

        &:focus{
          box-shadow: none;
        }
      }
    }

    .pagination {
      margin-bottom: 0;
      margin-top: 0.25rem;
      float: right;

      .page-item {

        .page-link {
          padding: 0.10rem 0.50rem;
        }
      }
    }
  }
}
