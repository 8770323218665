.treeview {
  padding-left: 0.75rem;
  padding-top: 0.5rem;

  li {

    list-style: none;
    line-height: 26px;

    .treeview-label {

      &.label-active {
        color: $primary;
      }

      .label-icon {
        margin-top: -3px;
        cursor: pointer;
      }

      .label-text {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    ul {
      margin-left: 0.5rem;
      padding-left: 1rem;
      border-left: 1px dotted #aaa; 
      margin-bottom: 0.5rem;
    }
  }
}
