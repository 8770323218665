@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.full-page-loading {
  position: absolute;
  background: $gray-200;
  border-radius: 5px;
  padding: 1rem;
  max-width: 250px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $gray-700;

  .loading-spinner {
    margin-top: -2px;
    font-size: 30px;
    margin-right: 5px;
    animation: spin 4s linear infinite;
  }
}