
@import "variables";
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .app {
  height: 100%;
  overflow-y: hidden;
}

@import 'views/login';
@import 'views/layouts/app';

@import 'components/header_search';
@import 'components/rc_select';
@import 'components/input_table';
@import 'components/tables';
@import 'components/buttons';
@import 'components/dropdown';
@import 'components/toolbar';
@import 'components/treeview';
@import 'components/rc_table_compact';
@import 'components/simple_properties_table';
@import 'components/page_header';
@import 'components/react_dropzone_uploader';
@import 'components/notifications_dropdown';
@import 'components/error_debug_block';
@import 'components/input_section_sort';
@import 'components/header_tabs';
@import 'components/full_page_loading';
@import 'components/timeline';


.popover {
  z-index: auto;
}

.bs-popover-top {
  max-width: 100% !important;
}

.pre-like {
  white-space: pre-line;
}

.text-del {
  text-decoration: line-through;
}

.tab-content {
  height: 100%;
}

.react-icons {
  vertical-align: middle;
}

.badge {
  &.badge-purple {
    background: $purple;
    color: #fff;
  }

  &.badge-success-outline {
    background: transparent;
    border: 1px solid $success;
    color: $success;
  }
}