.app {
  display: flex;
  flex-direction: column;

  .app-header {
    flex: 0 0 auto;
    z-index: 99;

    .header-staging {
      height:8px;
      background: repeating-linear-gradient(
        -45deg,
        $warning,
        $warning 8px,
        $gray-800 8px,
        $gray-800 16px
      );
      border-top: 1px solid $gray-800;
      border-bottom: 1px solid $gray-800;
      position: relative;

      &::after {
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translateX(-50%);
        content: "Staging Environment";
        height: 16px;
        line-height: 14px;
        border: 2px solid $gray-800;
        border-radius: 3px;
        background: $warning;
        font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
      }
    }
  }

  .app-content {
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .content-container {
      flex: 1 1 auto;
      overflow-y: auto;
    }
  }

  .app-footer {
    flex: 0 0 auto;
    background: #f8f8f8;
    border-top: 1px solid #dbdbdb;
    z-index: 99;

    a {
      color: $body-color;
    }
  }
}

.navbar {
  padding: 0.25rem 1rem;

  .nav-link {
    color: rgba(#fff, 0.8) !important;

    &:hover {
      color: #fff !important;
    }
  }
}