.page-header {
  flex: 0 0 auto;
  background: #f8f8f8;
  z-index: 80;
  border-bottom: 1px solid #dbdbdb;

  &.has-tabs {
    padding-bottom: 0.5rem !important;
    border-bottom: 0;
  }
}
