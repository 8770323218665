.header-tabs {
  background: #f8f8f8;
  padding-left: 1rem;
  
  .nav-item {
    margin-right: 0.5rem;
  }
}

.tab-content {
  overflow-y: auto;
}