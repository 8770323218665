.inputTable {
  
  .btn {
    margin-top: -5px;
    margin-bottom: -5px;
  }


  thead {
    
    tr {
      th {
        border-bottom: 1px solid #dbdbdb;
        border-top: 0;
        background: #f8f8f8;
        height: 34px;
        padding: 0 0.5rem;
        font-size: 14px;
        border-right: 1px solid #dbdbdb;
        vertical-align: middle;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0;
        
        .btn-xs {
          margin: 8px;
          padding: 2px 5;
        }
      }
    }
  }

  .tableInput {
    width: 100%;
    border: 0;
    padding: 0.75em;
    background: transparent;
    border-radius: 0;
    border: 1px solid transparent;
    

    &:hover {
      background: $gray-100;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      background: $gray-100;
    }
  }

  select.tableInput {
    height: 50px;
  }
}


.tableTooltip {
  background: $danger;
  padding: 2px 10px;
  color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}