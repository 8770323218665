@import 'react-dropzone-uploader/dist/styles.css';

.dzu-dropzone {
    border: 0;
    overflow: auto;

    * { 
        font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    }

    .dzu-inputLabel {
        color: $gray-400;
        font-family: inherit;
        font-weight: inherit;
        font-size: 1rem;
    }

    .dzu-previewContainer {
        padding: 1rem;

        .dzu-previewImage {
            background: $gray-500;
            border-radius: 3px;
            padding: 5px;
        }

        .dzu-previewFileName {
            font-size: 1em;
        }
    }

    .dzu-inputLabelWithFiles {
        background: $gray_300;
        transition: background 0.3s;
        font-weight: 400;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        font-size: 1em;
        margin: auto;
        margin-top: 1em;
        color: $body-color;

        &:hover {
            background: $gray_400;
        }
    }
    
    .dzu-submitButtonContainer {
        padding: 0;
        margin: 1em;

        .dzu-submitButton {
            color: #fff;
            background: $primary;
            font-weight: 400;
            border: 1px solid transparent;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            transition: background 0.3s;

            &:hover {
                background: darken($primary, 10%);
            }
        }
    }
}