.table.table-cardlike-header {
  
  thead {

    tr {

      th {
        background-color: rgba(0, 0, 0, 0.03);
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-bottom: 0 !important;
      }
    }
  }
}
