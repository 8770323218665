.timeline {

  .timeline-item {
    padding-left: 25%;
    width: 100%;
    //background:green;
    position: relative;

    .item-meta {
      position: absolute;
      left: 0;
      width: 25%;
      text-align: right;
      padding-right: 1em;
     // background: red;
    }

    .item-content {
      width: 100%;
      position: relative;
      padding-left: 1em;
      padding-bottom: 1em;

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 4px;
        background: #eee;
        left: -2px;
      }

      &::after {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 4px solid $secondary;
        background: #fff;
        left: -7px;
        top: 5px;
      }
      
      h6 {
        padding-top: 2px;
        margin-bottom: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    @each $variant, $color in $theme-colors {
      &.item-#{$variant} {
        .item-content {
          &::after {
            border-color: $color;
          }
        }
      }
    }

  }
}