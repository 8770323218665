.toolbar {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  background: #f8f8f8;
  display: flex;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 34px;
  min-height: 34px;
  
  &.no-top-border {
    border-top: 0;
  }

  .toolbar-button {
    min-width: 26px;
    padding-bottom: 2px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    color: $body-color;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    .button-label {
      display: inline-block;
      font-size: 14px;
    }

    svg + .button-label {
      margin-left: 5px;
    }
  }

  .toolbar-spacer {
    flex: 1 1 auto;
  }
}
