.notifications-dropdown {
  .dropdown-card {
    width: 350px;
    border: 0;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;

    .card-body {
      height: 400px;
      overflow-y: scroll;
      padding: 0;
    }
  }
}