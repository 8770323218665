.simple-properties-table {
  width: 100%;

  &.table-border-top {
    border-top: 1px solid #dbdbdb;
  }

  &.table-border-bottom {
    border-bottom: 1px solid #dbdbdb;
  }

  thead {
    tr {
      th {
        padding: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-bottom: 1px solid #dbdbdb;
        font-size: 14px;
        background: #f8f8f8;
      }
    }
  }

  tbody {
    tr {
      th, td {
        padding: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-bottom: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
      }

      th {
        border-right: 1px solid #dbdbdb;
        width: 40%;
        font-weight: 400;
      }

      td {
        &:last-child {
          border-right: 0;
        }
      }

      &:last-child {

        th, td {
          border-bottom: 0;
        }
      }


      &.thead {
        th {
          font-size: 14px;
          background: #f8f8f8;
          font-weight: 600;
        }
      }
    }
  }
}
